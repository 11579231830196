<template>
    <v-row>
        <v-col cols="9">
            <div class="d-flex justify-end mb-8">
                <v-btn icon @click="clearTemplate">
                    <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
            </div>
            <draggable :list="selectedComponents">
                <transition-group>
                    <template v-for="(component, key) in selectedComponents">
                        <div :key="key"
                             class="component"
                             @mouseenter="displayComponentPanel(key, true)"
                             @mouseleave="displayComponentPanel(key, false)"
                        >
                            <div :class="['component__panel', {active: !!(component.showPanel)}]">
                                <v-btn icon
                                       @click="editComponent(key)"
                                       v-if="component.componentOptions">
                                    <v-icon>mdi-cog</v-icon>
                                </v-btn>
                                <v-btn icon @click="removeComponent(key)">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </div>
                            <component
                                :is="component.name"
                                :props="component.props"
                                v-if="componentList.some(c => c.name === component.name)"
                            />
                        </div>
                    </template>
                </transition-group>
            </draggable>
        </v-col>
        <v-col cols="3">
            <div class="mb-8">
                <v-btn @click="saveTemplate" color="primary" block>Save</v-btn>
            </div>
            <div>Components</div>
            <v-btn block
                   :key="id"
                   class="text-center"
                   v-for="(component, id) in componentList"
                   @click="addComponent(component)"
            >
                <span>{{ component.title }}</span>
            </v-btn>
        </v-col>
        <v-dialog
            persistent
            max-width="80vw"
            v-if="editableComponent"
            v-model="displayComponentOptions"
        >
            <v-card class="px-4 py-2">
                <v-container>
                    <component
                        :is="editableComponent.componentOptions"
                        :props="editableComponent.props"
                        v-on:save="saveComponentOptions"
                        v-on:close="closeComponentOptions"
                    />
                </v-container>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    name: "TemplateBuilder",
    props: ['page'],
    components: {draggable},
    data() {
        return {
            selectedComponents: [],
            displayComponentOptions: false,
            editableComponent: null,
            editableComponentKey: null,
            componentList: [
                {name: 'SimpleNavbar', title: 'Navbar', componentOptions: 'simpleNavbarOptions', props: {}},
                {name: 'SimpleText', title: 'Text', props: {}},
                // {name: 'SimpleFooter', title: 'Simple Footer', props: {}},
            ]
        }
    },
    watch: {
        page() {
            this.selectedComponents = this.page.template.components || []
        }
    },
    methods: {
        saveTemplate() {
            this.page.template = {components: this.selectedComponents}
            this.page.save()
        },
        saveComponentOptions(props) {
            this.selectedComponents[this.editableComponentKey].props = props
            this.editableComponent = null
            this.editableComponentKey = null
            this.displayComponentOptions = false
        },
        closeComponentOptions() {
            this.editableComponent = null
            this.editableComponentKey = null
            this.displayComponentOptions = false
        },
        clearTemplate() {
            this.selectedComponents = []
        },
        editComponent(key) {
            this.editableComponentKey = key
            this.editableComponent = this.selectedComponents[key]
            this.displayComponentOptions = true
        },
        removeComponent(key) {
            this.selectedComponents.splice(key, 1)
        },
        displayComponentPanel(key, value) {
            const component = this.selectedComponents[key]
            component.showPanel = value
            this.selectedComponents.splice(key, 1, component)
        },
        addComponent({name, title, componentOptions, props}) {
            this.selectedComponents.push({name, title, componentOptions, props: {...props}})
        }
    },
    created() {
        this.selectedComponents = this.page.template.components || []
        this.selectedComponents = this.selectedComponents.map(c => {
            c.showPanel = false
            return c
        })
        this.componentList = this.componentList.map(c => {
            c.showPanel = false
            return c
        })
    }
}
</script>

<style scoped>
.component {
    position: relative;
}

.component__panel {
    display: none;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    left: 0;
    top: -33px;
    background: #e7e7e7;
    border-radius: 5px 5px 0 0;
}

.component__panel.active {
    display: flex;
}
</style>
