<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col md="5">
                <v-select
                    label="Store"
                    v-model="store"
                    :items="stores"
                    item-text="name"
                    item-value="name"
                ></v-select>
            </v-col>
            <v-col md="5">
                <v-select
                    label="Page"
                    v-model="page"
                    :items="pages"
                    item-text="type"
                    item-value="type"
                ></v-select>
            </v-col>
        </v-row>
        <template-builder v-if="activePage" :page="activePage"/>
    </admin-dashboard-layout>
</template>

<script>
import Page from "@/models/Page";
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import TemplateBuilder from "@/views/admin/pages/components/template-builder/TemplateBuilder";

export default {
    name: "content-index",
    components: {TemplateBuilder, AdminDashboardLayout},
    data: function () {
        return {
            stores: [],
            store: null,
            pages: [],
            page: null,
            allPages: [],
            activePage: null,
        }
    },
    watch: {
        store(store) {
            this.pages = this.allPages.filter(p => p.store.name === store)
        },
        pages() {
            this.activePage = this.pages
                .find(p => p.store.name === this.store && p.type === this.page)
        },
        page() {
            this.activePage = this.allPages
                .find(p => p.store.name === this.store && p.type === this.page)
        },
    },
    async created() {
        this.allPages = await Page.get()
        this.stores = process.env.VUE_APP_STORE_DOMAINS.split(',')
        this.store = this.stores[0]
        this.pages = this.allPages.filter(p => p.store.name === this.store)
        if (this.allPages.length) {
            this.page = this.allPages[0].type
            this.activePage = this.allPages[0]
        }
    }
}
</script>

<style scoped>

</style>
